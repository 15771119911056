<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="addNewTerminalForm"
        ref="addNewTerminalForm"
        v-model="isFormValid"
        class="custom-form pt-8"
        @submit.prevent="submitNewTerminalForm"
      >
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              label="Nazwa terminalu"
              v-model="terminal.name"
              :rules="[rules.required]"
              placeholder="Wpisz nazwę terminalu"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              outlined
              label="Nr IMEI"
              v-model="terminal.imei"
              placeholder="Wpisz numer IMEI"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              outlined
              label="Numer telefonu"
              v-model="terminal.phoneNumber"
              :rules="[rules.required, rules.phoneNumber]"
              v-mask="getFieldMask('phoneNumber')"
              placeholder="Wpisz numer telefonu"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        type="submit"
        form="addNewTerminalForm"
      >
        Dodaj terminal
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import rules from '../../utils/validators'
import DialogFormWrapper from './Partials/DialogFormWrapper'
import { getFieldMask } from '../../utils'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    DialogFormWrapper
  },
  data () {
    return {
      rules,
      isFormValid: true,
      terminal: {
        name: null,
        imei: null,
        phoneNumber: null,
      }
    }
  },
  computed: {
    ...mapState({
      department: state => state.core.department,
      isProcessing: state => state.terminals.isProcessing
    })
  },
  methods: {
    ...mapActions({
      addNewTerminal: 'terminals/addNewItem',
      getTerminals: 'terminals/getItems',
      closeDialog: 'layout/closeDialog'
    }),
    getFieldMask,
    submitNewTerminalForm () {
      this.$refs.addNewTerminalForm.validate()
      if (this.isFormValid) {
        const params = {
          ...this.terminal,
          departmentId: this.department.id
        }
        this.addNewTerminal({ params })
          .then(() => {
            this.getTerminals()
            this.closeDialog()
          })
      }
    }
  }
}
</script>
